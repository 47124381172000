<template>
  <div class="mx-auto subcat-navigation image-with-link">
    <ul class="nav first-level-nav level0" ref="scrollableList" @mousemove="overPosition" @touchmove="overPosition($event, useTouch = true)" :style="{ transform: `translate(${userPosition})`}">
      <li v-for="(subcategory, index) in orderedCategory" :key="subcategory.url_path" class="navbar-item animate__animated animate__fadeInRight" :class="[`animate__delay-0${index}`]">
        <router-link :to="'listing' | localizedByNameCategories(subcategory.url_path, null, $store, $router)" class="link-indent">{{ subcategory.name }}</router-link>
        <NavImage :category="subcategory" />
        <span class="link-label">{{ subcategory.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery'
import { sortBy, filter, throttle } from 'lodash'
import NavImage from '../NavImage'

export default {
  name: 'ImageWithLink',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userPosition: null
    }
  },
  computed: {
    orderedCategory () {
      return sortBy(filter(this.category.childrenData, { include_in_menu: true, is_active: true }), ['position'])
    }
  },
  methods: {
    overPosition: throttle(function (e, useTouch = false) {
      const $el = $(this.$refs.scrollableList)
      let windowData = {
        width: $(window).width(),
        // height: $(window).height(),
        halfWidth: $(window).width() / 2,
        // halfHeight: $(window).height() / 2,
        elWidth: $el.width(),
        elHeight: $el.height()
      }
      const y = this.getOffset(this.$refs.scrollableList)
      // get coordinates Y - X from mobile or desktop
      // let pageY = (useTouch) ? e.changedTouches[0].pageY : e.pageY
      let pageX = (useTouch) ? e.changedTouches[0].pageX : e.pageX
      // let centerPosH = (windowData.elHeight - windowData.height) / 2
      let centerPosW = (windowData.elHeight - windowData.width) / 2
      // let moveY = (-centerPosH * (pageY - y.top)) / windowData.halfHeight
      let moveX = (centerPosW * (pageX - y.top)) / (windowData.width / 2)
      this.userPosition = `${parseInt(moveX)}px, 0`
      return this.userPosition
    }, 200),
    getOffset (el) {
      let rect = el.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
  },
  components: {
    NavImage
  }
}
</script>
